import styled from '@emotion/styled';
import {Container} from 'react-bootstrap'

export const Nav = styled.nav`
	background: #000;
	box-shadow: 1px 1px 1px 1px rgba(256,255, 256, 0.2);
	height: 80px;
	padding: 10px 0px;
	width: 100%;

	@media screen and (min-width: 768px) {
		position: ${ props => props.absolute? 'absolute' : 'relative'};
	}
`

export const NavContainer = styled(Container)`
	justify-content: space-between;
	display: flex;
	align-items: center;
`

export const TogglableMenu = styled.button`
	color: white;
	background: none;
	border: none;
	color: #eee;
	display: ${props => props.showMenu? 'initial' : 'none'};
	&:hover{
		color: white;
	}

`

export const Brand = styled.div`
	margin-right: 10px;
	height: inherit;

	img{
		height: inherit;
	}

`
