import React from 'react';
import useArticles from '../../hooks/useArticles';
import {Container} from 'react-bootstrap';
import Layout from '../components/Layout';
import ArticleCard from '../components/ArticleCard';
import ListArticles from '../components/ListArticles';
import Pagination from '../components/Pagination';



const ArticlesAdmin = ({location}) => {

    const {search} = location;
    const querys = new URLSearchParams(search);
    const page = querys.get('page');


	const {articles, 
        removeArticle,
        nextPage,
        prevPage,
        currentPage,
        totalPages
    } = useArticles({limit: 12, page});

  return (
    <Layout >
    	<Container fluid>
    		<h1 className="text-center ">Articles</h1>   			
	    
	    	<ListArticles>
	    		{articles && articles.map( article =>
	    			<ArticleCard
	    				key={article.id}
	    				article={article}
	    				removeArticle={removeArticle}
	    				/>
	    		)}
	    	</ListArticles>

            <Pagination
                next={nextPage}
                prev={prevPage}
                current={currentPage}
                totalPages={totalPages}
            />
    	</Container>
    </Layout>
  )
}

export default ArticlesAdmin;