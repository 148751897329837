import MultiCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {Link} from "react-router-dom";
import useArticles from '../../../hooks/useArticles';
import { Image } from 'cloudinary-react';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const Carousel = (props) => {
  const {articles} = useArticles({limit: 4});


  return(
  <MultiCarousel
        swipeable={true}
        draggable={false}
        showDots={true}
        responsive={responsive}
        ssr={false}
        infinite={true}
        arrows={false}
        autoPlay={false}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        customTransition="transform 300ms ease-in-out"
        transitionDuration={500}
        containerClass="carousel-container height-90vh"
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {articles.map((article) => (
          <div key={article.id}>
            <Link to={"/article/" + article.id}>
              <div className="bg-image text-container">
                <Image
                  style={{ width: "100%", height:'450px', 'objectFit': 'cover' }}
                  alt="First slide"
                  className="img-carousel"
                  cloudName={process.env.REACT_APP_CLOUDINARY_NAME}
                  publicId={article.imagePrincipalURL}
                  width="800"
                  crop="scale"
                />
                <div className="text-bottom">
                  <h3
                    style={{
                      fontFamily: "Roxborough, serif",
                      textShadow: "1px 1px 2px black",
                      textAling: 'left'
                    }}
                  >
                    {article.title}
                  </h3>
                </div>
              </div>
            </Link>
          </div>
        ))}
</MultiCarousel>
)}

export default Carousel;
