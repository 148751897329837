import React from 'react';
import {Container} from 'react-bootstrap'
import {createArticle} from '../../services/articlesServices';
import Layout from '../components/Layout';
import ArticleForm from '../components/ArticleForm';


const AddArticleAdmin = (props) => {
  return (
    <Layout>
    	<Container>
	    	<h1 className="text-center my-3">Add New Article</h1>
	    	<ArticleForm
	    		submit={createArticle}
	    	/>
    	</Container>
    </Layout>
  )
}

export default AddArticleAdmin;