import React from "react";
import Layout from '../components/Layout';


export default function AboutPage() {
  return (
    <Layout>
      <title>Mic Check - Sound Check</title>
      <div className="container real-white text-white">
        <h1>About us</h1>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis
          in aspernatur, nemo saepe quam ex quas explicabo doloribus optio illum
          maxime reiciendis harum libero iusto ipsa ipsam, odit nobis atque
          exercitationem expedita, placeat incidunt quibusdam. Excepturi qui,
          voluptas doloribus aspernatur praesentium est, cum nam quos mollitia
          ullam non quis nobis voluptates magnam enim iure eum! Non aspernatur
          omnis provident architecto sit quisquam nobis, mollitia deleniti
          facere iste, ut doloremque perspiciatis ab maiores nostrum! Culpa
          impedit eum quos magni provident id deserunt. Quod voluptates ullam
          vitae quidem. Delectus cum incidunt nemo, iusto molestias est eveniet
          tempore perferendis praesentium ex quis amet.
        </p>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequuntur
          earum nesciunt quo sunt veniam explicabo alias sit voluptatem! Saepe
          consequatur earum iste ducimus, dolorem nobis optio esse ea explicabo
          ipsum.
        </p>
      </div>
    </Layout>
  );
}
