import styled from '@emotion/styled';

export const ArticleCard = styled.article`
	/* height: 200px; */
	overflow: hidden;
	margin: 40px auto;
	max-width: 1200px;
	padding: 0 20px;
	font-size: 1rem;

	a{

		font-family: 'Caveat Brush', cursive;
		font-size:20px;

	}

	& > img{
		/* height: inherit; */
		float: left;
		margin-right: 20px;
		object-fit: cover;
	}


	h2{
		margin-top: 0;
		margin-bottom: 3px;
		line-height: 1;
	}

	p{
		margin-top: 20px;
		/* color: var(--bs-white); */
		color: white;
		font-size: 0.9em;
		display: -webkit-box;
		text-overflow: ellipsis;
		-webkit-line-clamp: 2;
		-moz-box-orient: vertical;
		overflow: hidden;

	}

	@media screen and (max-width: 520px){
		display: flex;
		flex-direction: column;
		height: initial;


		& > img{
			width: 100%;
			height: auto;
			margin-right: 0;
			margin-bottom: 20px;
		}



	}
`
