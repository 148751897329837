import React from "react";
import { Route} from "react-router-dom";
import PrivateRoute from './admin/components/PrivateRoute';
import AuthContext from './authContext/AuthContext';
import Home from "./users/pages/HomePage";
import About from "./users/pages/AboutPage";
import Contact from "./users/pages/ContactPage";
import Article from "./users/pages/ArticlePage";
import Podcasts from "./users/pages/PodcastsPage";
import Magazine from "./users/pages/MagazinePage";
import Login from "./admin/pages/LoginPage";
//import Admin from "./admin/pages/AdminPage";
import ArticlesAdmin from "./admin/pages/ArticlesAdmin";
import AddArticleAdmin from "./admin/pages/AddArticleAdmin";
import EditArticleAdmin from "./admin/pages/EditArticleAdmin";

function App() {
  return (
    <AuthContext>
      <div className="App">
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/podcasts" component={Podcasts} />
        <Route exact path="/article/:id" component={Article} />
        <Route exact path="/magazine/:id" component={Magazine} />
        <Route exact path="/login" component={Login} />
        <PrivateRoute exact path="/admin" component={ArticlesAdmin} />
        <PrivateRoute exact path="/admin/articles" component={ArticlesAdmin} />
        <PrivateRoute exact path="/admin/add-article" component={AddArticleAdmin} />
        <PrivateRoute exact path="/admin/edit-article/:idArticle" component={EditArticleAdmin}/>

      </div>
    </AuthContext>
  );
}

export default App;
