import React from 'react';
import { faFacebookF,faTwitter,faTiktok,faSpotify} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Link} from 'react-router-dom'

const styleobject = {

  socialIcons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginBottom: '30px',
    marginLeft: '8vw',
  }
}

const SubHeader = (props) => {
  return (
    <div style={styleobject.socialIcons} >
    <Link style={{color:'white'}} target="_" to={{pathname: 'https://www.facebook.com/Mic-Check-105904435259128'}}> <FontAwesomeIcon
        icon={faFacebookF}
       />
       </Link>


          <Link style={{color:'white'}}  target="_" to={{pathname: 'https://twitter.com/micheckofficial'}} > <FontAwesomeIcon
                icon={faTwitter}
               />
               </Link>
<Link style={{color:'white'}}  target="_" to={{pathname: 'https://open.spotify.com/user/gh6wjvoj72j09imd0x0tdktd3'}} >
          <FontAwesomeIcon
              icon={faSpotify}
             />
              </Link>

            <Link style={{color:'white'}}  target="_" to={{pathname: 'https://www.tiktok.com/@miccheck_official'}} >
             <FontAwesomeIcon
                 icon={faTiktok}
                />
                </Link>
    </div>
  )
}

export default SubHeader;
