import styled from '@emotion/styled';

export const Nav = styled.nav`
	border-right: 2px solid rgb(25,25,25);
	display: flex;
	flex-direction: column;
	padding-top: 30px;
	width: 100%;

	a, button{
		border-bottom: 1px solid #aaa;
		color: #eee;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-decoration: none;
		transition: 0.3s; 
		font-family: 'Caveat Brush', cursive;
		font-size: 1.2em;
		width: inherit;
		
		&:hover{
			color: black;
			background: white;
		}
	}

	button{
		background: none;
		border: none;

		&:focus{
			outline: none;
		}
	}


	@media screen and (min-width: 768px) {
		min-height: 100vh;
		height: 100%;
	}

`;