import styled from '@emotion/styled';

export const PaginationStyle = styled.div`
	display: flex;
	justify-content: center;

	button{
		color: white;
		background: none;
		border: none;
		font-size: 2.5rem;
		margin: 0 20px 20px 20px;
		&:focus{
			outline: none;
		}
	}
`; 