import styled from '@emotion/styled';


export const CardArticle = styled.article`
	background: #222;
	border-radius: 10px;
	overflow: hidden;
	width: 100%;
	h5{
		margin: 5px
	}
	span{
		margin: 5px;
	}

	img{
		width: 100%;
		height: 200px;
		object-fit: cover; 
	}

	
`;