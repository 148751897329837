import styled from '@emotion/styled';


export const ListArticles = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 250px));
	justify-content: center;
	gap: 50px;
	margin-bottom: 20px;

`

export default ListArticles;