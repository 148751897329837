import React from 'react';
import {PaginationStyle} from './style';

const Pagination = ({next, prev, totalPages, current ,listRef}) => {

  const handleClick = (fn) => {
    listRef.scrollIntoView();
    fn()
  }

  return (
    <PaginationStyle>
    	{current !== 1 &&
	    	<button onClick={()=> handleClick(prev)}> &#60; </button>
    		}
    	{current !== totalPages &&
	    	<button onClick={()=> handleClick(next)}> &#62; </button>
    		} 
    </PaginationStyle>
  )
}

export default Pagination;