import React,{useState} from 'react';
import {Row, Col} from 'react-bootstrap';
import Navbar from '../Navbar';
import AdminNav from '../AdminNav';

const Layout = (props) => {

    const [menuVisible, setMenuVisibile] = useState(true);

  return (
    <div className="overflow-hidden">
    	<Navbar
            setMenuVisibile={setMenuVisibile}
        />
    	<Row>
            {menuVisible?
                <>
                    <Col md={3} className="p-0">
                        <AdminNav/>
                    </Col>
                    <Col md={9} className="p-0">
                        {props.children}
                    </Col> 
                </> 
                :
                    <Col md={12} className="p-0">
                        {props.children}
                    </Col> 
                }
    			
    	</Row>
    </div>
  )
}

export default Layout;