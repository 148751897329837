import {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {getArticles, deleteArticle} from '../services/articlesServices';

const useArticles = (querys) => {
	const {title, author, sortBy = 'createdAt:desc', limit, page = 1} = querys;
	const history = useHistory()


	const [articles, setArticles] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [alert, setAlert] = useState(false);
	const [pageState, setPageState] = useState({
		currentPage: 1,
		totalPages: 1,		 
	});
	const {currentPage, totalPages} = pageState;

	useEffect(()=>{
		setIsLoading(true);
		getArticles({title, author, sortBy, limit, page})
		.then( res => {
			setArticles(res.results);
			setPageState({
				currentPage: res.page,
				totalPages: res.totalPages
			})
			setIsLoading(false);
		})
		.catch( e => setAlert(e.response?.data?.message));
	},[title, author, sortBy, limit, page]);


	const nextPage = () => {
		const nextNumberPage = currentPage + 1 > totalPages? totalPages : currentPage+1;	
		history.push(`${history.location.pathname}?page=${nextNumberPage}`)
	}

	const prevPage = () => {
		const nextNumberPage = currentPage - 1 < 1? 1 : currentPage-1;		
		history.push(`${history.location.pathname}?page=${nextNumberPage}`)		
	}

	const removeArticle = (id) =>{
		deleteArticle(id)
       .then(() => setArticles(articles.filter(article => article.id !== id)))
       .catch(e => setAlert(e.response?.data?.message))

       
	} 

	return {articles, 
			isLoading, 
			alert, 
			nextPage, 
			prevPage,
			removeArticle,
			currentPage,
			totalPages
		};
}

export default useArticles;