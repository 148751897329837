import styled from '@emotion/styled';


export const ArticleStyle = styled.div`
	padding: 0 20px;
	margin: 20px auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 850px;

	figure{
		width: 100%
	}

	img{
		width: 100%;
		height: 100%
		height: auto;
		margin: 10px 0;
	}
`

export const TextArticle = styled.div`
	margin-top: 20px;
	p{
		color: white;
		line-height: 2;
		margin: 0;
		font-size: 1.2em;
	}

	& > img{
		height: 200px;
		width: 200px;
		object-fit: cover;
		clip-path: circle(50%);
		margin: 20px;
		clear: both;

		&:nth-of-type(odd){
			float:left;
		}
		&:nth-of-type(even){
			float:right;
		}
	}

	@media screen and (max-width: 450px){
		/*img{
			height: 100px;
			width: 100px;
		}*/
		display: flex;
		flex-direction: column;
		align-items: center;

		img{
			float: initial;

			&:nth-of-type(even){
				order: 2;
			}

		}

		& > p:first-of-type{
			order: -1;
		}

	}


`
