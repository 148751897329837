import React, {useState, useEffect} from 'react';
import {getArticle, updateArticle } from '../../services/articlesServices';
import {Container} from 'react-bootstrap'
import Layout from '../components/Layout';
import ArticleForm from '../components/ArticleForm';


const EditArticleAdmin = ({match}) => {
  
  const {idArticle} = match.params;
  const [article, setArticle] = useState(null);

  useEffect(()=>{
  	getArticle(idArticle)
  	.then( res => setArticle(res.data))

  },[idArticle])

  return (
    <Layout>
    	<Container>
	    	<h1 className="text-center my-3">Edit Article</h1>
	    	{article &&
	    	<ArticleForm
	    		article={article}
          idArticle={idArticle}          
	    		submit={updateArticle}
	    	/>}
    	</Container>
    </Layout>
  )
}

export default EditArticleAdmin;