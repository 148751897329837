import React, {useState} from 'react';
import {Form, Button} from 'react-bootstrap';
const Login = ({submit, error}) => {
	
	const[loginData, setLoginData] = useState({
		email: '',
		password: ''
	})

	const [alert, setAlert] = useState(false);

	const {email, password} = loginData;

	const handleChange = e => {
		setLoginData({
			...loginData,
			[e.target.name]: e.target.value
		})
	}

	const handleSubmit = e => {
		e.preventDefault();

		if(email.trim() === ''){
			setAlert('Email is required');
			return;
		}

		if(password.trim() === ''){
			setAlert('Password is required');
			return;
		}

		setAlert(false);

		submit(email, password)
	}
  return (
    <Form 
    	className="p-4 border rounded text-light"
    	onSubmit={handleSubmit}
    	>
    	<h1 className="text-center pb-3 f">Login</h1>
	  <Form.Group controlId="formBasicEmail">
	  	
	    <Form.Label className="text-light">Email address</Form.Label>
	    <Form.Control 
	    	type="email" 
	    	placeholder="Enter email"
	    	value={email}
	    	name="email"
	    	onChange={handleChange}
	    	required
	    />	
	  </Form.Group>

	  <Form.Group controlId="formBasicPassword">
	    <Form.Label className="text-light">Password</Form.Label>
	    <Form.Control 
	    	type="password"
	    	placeholder="Password"
	    	value={password}
	    	name="password"
	    	onChange={handleChange}	    	 
	    	required
	    />
	  </Form.Group>
	  {alert && 
	  	<div className="alert alert-danger text-center" role="alert">
		  {alert}
		</div>
	  	}

		{error&& 
		  	<div className="alert alert-danger text-center" role="alert">
			  {error}
			</div>
		}


	  <Button variant="primary" type="submit" className="w-100 mt-3">
	    Sign In
	  </Button>
	</Form>
  )
}

export default Login;