import React, {useContext} from 'react';
import {Route, Redirect} from 'react-router-dom';
import {authContext} from '../../authContext/AuthContext';
import Spinner from 'react-bootstrap/Spinner';

const PrivateRoute = ({component: Component, ...props}) => {

	const {isAuth, isLoading} =  useContext(authContext);

    // if(isLoading){
    //   return (
    //     <div className="d-flex justify-content-center align-items-center min-vh-100">
    //       <Spinner animation="border" role="status">
    //           <span className="sr-only">Loading...</span>
    //       </Spinner>
    //      </div>
    //   )
    // }
		//
	  return (

    <Route {...props} render = { props => isAuth? (
    		<Component {...props}/>
    	): (
    		<Redirect to="/login" />
    	)}/>


);
}

/*
  loading?
    <div className="d-flex justify-content-center align-items-center min-vh-100">
          <Spinner animation="border" role="status">
              <span className="sr-only">Cargando...</span>
          </Spinner>
    </div>
    :
  */

export default PrivateRoute;
