import Axios from '../config/Axios';
import verifyToken from '../config/verifyToken';


export async function getArticles(querys){	
	const {title, author, sortBy, limit, page} = querys;
	console.log(sortBy)
	verifyToken();
	try{
		const response = await Axios.get('articles',{
			params: {title, author, sortBy, limit, page}
		});
		return response.data
	}catch(e){
		console.log(e.response.data.message);
	}

}

export async function createArticle(data){
	verifyToken();	
	const response = await Axios.post('articles', data);
	return response;	
}

export async function getArticle(id){
	verifyToken();
	const response = await Axios.get(`articles/${id}`);
	return response;
}

export async function deleteArticle(id){
	verifyToken();	
	const response = await Axios.delete(`articles/${id}`);
	return response;
}

export async function updateArticle(data, id){
	verifyToken();
	const response = await Axios.patch(`articles/${id}`, data);
	return response.data;	
}

