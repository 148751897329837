import React from 'react';
import {useHistory} from 'react-router-dom';
import { Image } from 'cloudinary-react';
import {CardArticle} from './style';
import {Button} from 'react-bootstrap';

const ArticleCard = ({article, removeArticle}) => {
    const {id, title, author, imagePrincipalURL} = article;
    const history = useHistory();

    const handleRedirect = () => {
        history.push(`/admin/edit-article/${id}`)
    }

    
  return (
    <CardArticle>
    	<Image
            cloudName={process.env.REACT_APP_CLOUDINARY_NAME}
            publicId={imagePrincipalURL} 
            width="800"
            crop="scale"
        />
    	<h5>{title}</h5>
    	<span>Author: {author}</span>
    	<div className="d-flex justify-content-between m-2">
    		<Button onClick={handleRedirect}>
    			Edit
    		</Button>
    		<Button variant="danger" onClick={() => removeArticle(id)}>
    			Remove
    		</Button>
    	</div>
    </CardArticle>
  )
}

export default ArticleCard;