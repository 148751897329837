import React from 'react';
import {Link} from 'react-router-dom';
import { Image } from 'cloudinary-react';
import {ArticleCard} from './style';

const CardArticles = ({article}) => {

  const {title,text, id, imagePrincipalURL} = article

  return (
    <ArticleCard>
    	 <Image
          cloudName={process.env.REACT_APP_CLOUDINARY_NAME}
          publicId={imagePrincipalURL}
          width="300"
          crop="scale"
          loading="lazy"
       />
    	<h2>{title}</h2>
    	<p>
         {text.slice(0, 100)}...
      </p>
      <div >
        <Link to={`./article/${id}`}>
	         Read More...
	       </Link>
      </div>

    </ArticleCard>
  )
}

export default CardArticles;
