import React from "react";
import Layout from '../components/Layout';

export default function ContactPage() {
  return (
    <Layout>
      <div className="container real-white text-white">
        <h1>Contact us</h1>
        <ul>
          <li>
            <a href="/contact">Whatsapp +99 999 999</a>
          </li>
        </ul>
      </div>
    </Layout>
  );
}
