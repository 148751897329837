import React, {useState, useEffect} from 'react';
import {getArticle} from '../../services/articlesServices';
import Layout from '../components/Layout';
import Article from '../components/Article';

const ArticlePage = ({match, history}) => {

	const {id} = match.params;
  const [article, setArticle] = useState(null);

  useEffect(() => {
    getArticle(id)
    .then( res => setArticle(res.data))
    .catch(e => history.push('/'))    
    // eslint-disable-next-line
  },[id]);


  return (
    <Layout>
      {
      article &&
        <Article
          article={article}
        />
      }
    </Layout>
  )
}

export default ArticlePage;