import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Layout from '../components/Layout';

const test = {
  title: "Magazine number",
  images: [
    "veil-1.jpg",
    "veil-2.jpg",
    "veil-3.jpg",
    "veil-4.jpg",
    "veil-5.jpg",
    "veil-6.jpg",
    "veil-7.jpg",
    "veil-8.jpg",
    "veil-9.jpg",
    "veil-10.jpg",
    "veil-11.jpg",
    "veil-12.jpg",
  ],
};

const magazines = [
  {
    id: 1,
    text: "Loud luxury",
    title: "Released new album",
    img: "loudluxuryresize.jpg",
  },
  {
    id: 2,
    text: "Rufus du sol",
    title: "Released new album",
    img: "rufusdesolresize.jpg",
  },
  {
    id: 3,
    text: "Tiesto",
    title: "Released new album",
    img: "tiestoresize.jpg",
  },
  {
    id: 4,
    text: "Krewella",
    title: "Released new album",
    img: "krewellaupdate.jpg",
  },
];

const OneImage = ({ image, title }) => {
  return (
    <Row style={{ marginTop: "3%", marginBottom: "3%" }}>
      <Col>
        <div className="text-container">
          <img
            style={{ width: "100%" }}
            src={"/images/test-page/" + image}
            alt=""
          />
          {title ? (
            <div className="text-centered-top">
              <h1 style={{ fontFamily: "Roxborough,serif" }}>{title}</h1>
            </div>
          ) : null}
        </div>
      </Col>
    </Row>
  );
};

const TwoImages = ({ first, second }) => {
  return (
    <Row>
      <Col>
        <Row>
          <img
            style={{ width: "100%" }}
            src={"/images/test-page/" + first}
            alt=""
          />
        </Row>
        <Row>
          <h5 className="font-bad-script" style={{ marginTop: "10px" }}>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Expedita
            qui temporibus iusto asperiores. Quaerat sunt quis incidunt ex
            voluptatem minima doloribus, at, quo asperiores neque soluta dolorem
            quisquam beatae illum?
          </h5>
        </Row>
      </Col>
      <Col>
        <Row>
          <h5 className="font-bad-script" style={{ marginTop: "10px" }}>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Expedita
            qui temporibus iusto asperiores. Quaerat sunt quis incidunt ex
            voluptatem minima doloribus, at, quo asperiores neque soluta dolorem
            quisquam beatae illum?
          </h5>
        </Row>
        <Row>
          <img
            style={{ width: "100%" }}
            src={"/images/test-page/" + second}
            alt=""
          />
        </Row>
      </Col>
    </Row>
  );
};

const Presentation = ({ images, magazine }) => {
  return (
    <Container>
      <OneImage image={images[0]} title={magazine.text} />
      <TwoImages first={images[1]} second={images[2]} />
      <TwoImages first={images[3]} second={images[4]} />
      <OneImage image={images[5]} />
      <TwoImages first={images[6]} second={images[7]} />
      <TwoImages first={images[8]} second={images[9]} />
    </Container>
  );
};

export default function MagazinePage() {
  const { id } = useParams();
  const magazine = magazines.find((x) => x.id === Number(id));
  console.log(magazine);
  return (
    <Layout>
      <div className="container real-white text-white">
        <Presentation images={test.images} magazine={magazine} />
      </div>
    </Layout>
  );
}
