import React, {useEffect, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import {Row, Col,Container} from 'react-bootstrap';
import {authContext} from '../../authContext/AuthContext';
import Login from '../components/Login';
import Navbar from '../components/Navbar'

const LoginPage = (props) => {

	const {loginWithEmail, isAuth, error} = useContext(authContext); 
	const history = useHistory();

	useEffect(() => {
		if(isAuth){
			history.push('/admin/articles');
		}

		// eslint-disable-next-line
	} ,[isAuth])

  return (
  	<>
  	<Navbar absolute={true} showMenu={false}/>
  	<Container fluid>
	    <Row className="min-vw-100 min-vh-100 d-flex align-items-center">
	    	<Col
	    		md={{ span: 6, offset: 3 }}
	    		lg={{ span: 4, offset: 4 }}
	    		>
	    		<Login
	    			submit={loginWithEmail}
	    			error={error}
	    		/>	    	
	    	</Col>
	    	
	    </Row>
    </Container>
    </>
  )
}

export default LoginPage;