import React, {useState} from 'react';
import {Nav, Links, NavContainer,TogglableMenu, Brand, OpenIcon, CloseBar} from './style';
import {Link} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes} from '@fortawesome/free-solid-svg-icons'


const Navbar = (props) => {

	const[showNavbar, setShowNavbar] = useState(false);
	const [overlay, setOverlay ] = useState(false)

	const styleObject  = {
		closeBtn: {
			position: "absolute",
    top: "10%",
    right: "2px",
		fontSize: '40px'
	}
	}


  return (
    <Nav>
    	<NavContainer fluid>
    		<Link to='/' style={{textDecoration: 'none'}}>

			    <Brand>

			       <img src="/images/micchecklogo.jpg" alt="" />
						 <div>MIC CHECK</div>

			    </Brand>
		    </Link>
		     <TogglableMenu
		        type="button"
		        aria-expanded="false"
		        aria-label="Toggle navigation"
		        onClick={() => setShowNavbar(!showNavbar)}
		      >


{ !overlay && <FontAwesomeIcon onClick={() => setOverlay(!overlay)} style={{fontSize: '40px'}} icon={faBars}  />}
			</TogglableMenu>
			<Links className={showNavbar? 'active': ''}>
	<CloseBar>	<FontAwesomeIcon onClick={() => {
			setOverlay(!overlay)
			setShowNavbar(!showNavbar)
		}} style={styleObject.closeBtn} icon={faTimes}

		  />
			</CloseBar>

			    <Link to="/">Home</Link>
			    <Link to="/podcasts">Podcasts</Link>
					<Link to="/about">About</Link>
			    {/*<Link to="/news">News</Link>*/}
			    <Link to="/contact">Contact Us</Link>
			</Links>
		 </NavContainer>
	</Nav>


  )
}

export default Navbar;
