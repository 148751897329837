import React, {useState, useEffect} from 'react';
import { Image } from 'cloudinary-react';
import {ArticleStyle, TextArticle} from './style';


const Article = ({article}) => {
  const {title,text, imagePrincipalURL, imagesOptionalUrl, author, imgSource, articleDate} = article

  const [paragraphs, setParagraphs] = useState([]);

  useEffect(() => {
    setParagraphs(text.split('\n'));
  } ,[text])

  const formattedDate = new Date(articleDate)
.toLocaleDateString({},
  {timeZone:"UTC",month:"long", day:"2-digit", year:"numeric"}
  )


  return (
    <ArticleStyle>
        <title>{title}</title>
      	<h1>{title}</h1>
      	<h2>By: {author}</h2>
        <figure>
         <figcaption>{formattedDate}</figcaption>
      	{<Image
                      cloudName={process.env.REACT_APP_CLOUDINARY_NAME}
                      publicId={imagePrincipalURL}

                      crop="scale"
                    />}

        <figcaption>Image Source: {imgSource}</figcaption>
        </figure>

        <TextArticle>

          {imagesOptionalUrl[0] &&

            <Image
              cloudName={process.env.REACT_APP_CLOUDINARY_NAME}
              publicId={imagesOptionalUrl[0]}
              width="300"
              crop="scale"
            />

          }

          {imagesOptionalUrl[1] &&
            <Image
              cloudName={process.env.REACT_APP_CLOUDINARY_NAME}
              publicId={imagesOptionalUrl[1]}
              width="300"
              crop="scale"
            />
          }

          {paragraphs.map( (paragraph, index) =>
            <p key={index}>
              {paragraph}
            </p>
            )}

      </TextArticle>

    </ArticleStyle>
  )
}

export default Article;
