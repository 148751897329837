import React from 'react';
import Layout from '../components/Layout';

const Podcasts = (props) => {
  return (
    <Layout>
    <title>Mic Check - Is This Thing On?</title>
    	Podcasts
    </Layout>
  )
}

export default Podcasts;
