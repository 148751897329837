import React, {useState, useEffect ,useRef} from 'react';
import {useLocation} from 'react-router-dom';
import useArticles from '../../../hooks/useArticles';
import CardArticles from '../CardArticles';
import Pagination from '../Pagination';

const ListCardArticles = (props) => {
  const location = useLocation();
  const {search} = location;
  const querys = new URLSearchParams(search);
  const page = querys.get('page');

  const listRef = useRef()

  const {articles,
        currentPage,
        nextPage,
        prevPage,
        totalPages} = useArticles({limit: 14, page: page? page : 1 });

  const [articleToShow, setArticlesToShow] = useState([]);

  useEffect(() => {
    if(currentPage === 1){
      setArticlesToShow(articles.slice(4))
    }else{
      setArticlesToShow(articles);
    }

  },[articles, currentPage])


  return (
    <>
    <title>Mic Check - Providing the Latest Drops in EDM</title>
    <div ref={listRef}>
    	{articleToShow.map(article =>
    		<CardArticles
    			key={article.id}
    			article={article}
    			/>
    		)}
    </div>
    <Pagination
      next={nextPage}
      prev={prevPage}
      current={currentPage}
      totalPages={totalPages}
      listRef={listRef.current}
    />
    </>
  )
}

export default ListCardArticles;
