import React from 'react';
import Layout from '../components/Layout';
import Carousel from '../components/Carousel';
import ListCardArticles from '../components/ListCardArticles';
import SubHeader from '../components/SubHeader'


export default function HomePage() {
  return (
    <Layout>
       <SubHeader />  	
     	 <Carousel/>
     	 <ListCardArticles/>
    </Layout>
  );
}
