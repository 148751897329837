import React from 'react';
import {Nav, NavContainer,TogglableMenu, Brand} from './style';
import {Link} from 'react-router-dom'

const Navbar = ({absolute = false, showMenu = true, setMenuVisibile}) => {
  return (
    <Nav absolute={absolute}>
    	<NavContainer fluid>
    		<Link to='/'>
			    <Brand>
			       <img src="/images/micchecklogo.ico" alt="" />
			    </Brand>
		    </Link>
		     <TogglableMenu
		        type="button"
		        aria-expanded="false"
		        aria-label="Toggle navigation"
		        showMenu={showMenu}
		        onClick={() => setMenuVisibile(state => !state)}
		      >
			<span>Menu</span>
			</TogglableMenu>
		 </NavContainer>
	</Nav>

  )
}

export default Navbar;
