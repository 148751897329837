import React from 'react';
import {PaginationStyle} from './style';

const Pagination = ({next, prev, totalPages, current}) => {
  return (
    <PaginationStyle>
    	{current !== 1 &&
	    	<button onClick={prev}> &#60; </button>
    		}
    	{current !== totalPages &&
	    	<button onClick={next}> &#62; </button>
    		} 
    </PaginationStyle>
  )
}

export default Pagination;