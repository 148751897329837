import React from 'react';
import {logout} from '../../../services/authServices';
import {Link, useHistory} from 'react-router-dom';
import {Nav} from './style.js';

const AdminNav = (props) => {

    const history = useHistory();

    const handleLogout = () => {
        logout();
        history.push('/');
    }   

  return (
    <Nav>
    	<Link to='/admin/articles'>
    		Articles
    	</Link>
    	<Link to='/admin/add-article'>
    		Add Article
    	</Link>
    	<button onClick={handleLogout}>
    		Log Out
    	</button>
    </Nav>
  )
}

export default AdminNav;