import {createContext, useState, useEffect} from 'react';
import {login, verifyAuth} from '../services/authServices';

export const authContext = createContext();

const AuthContext = props => {

	const [isAuth, setIsAuth] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(false);

	useEffect(()=> {
		setIsLoading(true);
		setError(false);

		//verifyAuth()
		// .then( res => setIsAuth(true))
		// .then( _ => setIsLoading(false))
		// .catch( e => setIsAuth(false))

	},[]);

	const loginWithEmail = async (email, password) => {
		try{
			const response = await login(email,password);
			setIsAuth(true);
			localStorage.setItem('token', response.data.tokens.access.token);
				verifyAuth()
				.then( res => setIsAuth(true))
				.then( _ => setIsLoading(false))
	       .catch( e => setIsAuth(false))
		}catch(e){
			setError(e.response.data.message)
		}
	}

	return(
		<authContext.Provider
			value={{
				isAuth,
				loginWithEmail,
				isLoading,
				error
				}}
			>
			{props.children}
		</authContext.Provider>
	)

}



export default AuthContext;
