import Axios from './Axios.js';

const verifyToken = () => {
	const token = localStorage.getItem('token');
	if(token){
		Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	}else{		
		delete Axios.defaults.headers.common['Authorization'];
	}
}

export default verifyToken;