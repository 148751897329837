import React,{useState, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import {Form, Button, Alert} from 'react-bootstrap';
import {useFormik} from 'formik';
import * as Yup from 'yup';

const ArticleForm = ({article, submit,idArticle}) => {
	const history = useHistory();

	const [principalImage, setPrincipalImage] = useState(article? article.imagePrincipalURL : '');
	const [optionalImages, setOptionalImages] = useState(article? article.imagesOptionalUrl : []);
	const [alertImages, setAlertImages] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const formRef = useRef();
	const today = new Date();


	const initialValues = article?
	{title: article.title,author: article.author,text: article.text, imgSource: article.imgSource, articleDate: article.date}
	 :
	{title: '',author: '',text: '', imgSource: '', articleDate: ''}

	const formik = useFormik({
		initialValues,
		validationSchema: Yup.object({
			title: Yup.string()
					.required('Title is required'),
			author: Yup.string()
					.required('Author is required'),
			text: Yup.string()
					.required('Text is required'),
			imgSource: Yup.string()
							.required('Image Source is required'),
		  articleDate: Yup.date()
									.required('Date is required').max(today, "Date cannot be in the future")
		}),
		onSubmit: (data,actions) => {
			if(principalImage === ''){
				setAlertImages('Principal image is required');
				return;
			}

			data.imagePrincipalURL = principalImage;
			data.imagesOptionalUrl = optionalImages;

			setIsLoading(true);
			submit(data, idArticle)
			.then(() => {
				if(!article){
					setPrincipalImage('');
					setOptionalImages([]);
					setAlertImages(false);
					actions.resetForm();
					setIsLoading(false);
					formRef.current.reset()
				}else{
					history.push('/admin/articles');
				}

			})
			.catch(e => setAlertImages(e.response.data.mesagge))
		}
	})

	const handlePrincipalImage = e => {
		if(!e.target.files[0].type.includes('image')){
			setAlertImages("Principal image is not an image")
			return;
		}
		setAlertImages(false);
		const reader = new FileReader();
		reader.readAsDataURL(e.target.files[0])
		reader.onloadend = () => {
			setPrincipalImage(reader.result);
		}
		reader.onerror = () => {
           setAlertImages('Something went wrong');
        }
	}

	const handleOptionalImages = e => {
		setOptionalImages([]);
		const image1 = e.target.files[0];
		const image2 = e.target.files[1];

		if(image1){
			const reader = new FileReader();
			reader.readAsDataURL(image1)
			reader.onloadend = () => {
				setOptionalImages( images => [...images, reader.result]);
			}
			reader.onerror = () => {
	           setAlertImages('Something went wrong');
	        }
		}

		if(image2){
			const reader = new FileReader();
			reader.readAsDataURL(image2)
			reader.onloadend = () => {
				setOptionalImages( images => [...images, reader.result]);
			}
			reader.onerror = () => {
	           setAlertImages('Something went wrong');
	        }
		}

	}

	return (
		<div style={{maxWidth: '600px', margin: 'auto'}}>
			<Form
				onSubmit={formik.handleSubmit}
				ref={formRef}
				>
				<Form.Group controlId="formArticleTitle">
					<Form.Label className="text-white">Article Title:</Form.Label>
					<Form.Control
						type="text"
						name="title"
						value={formik.values.title}
						onChange={formik.handleChange}
						placeholder="Article Title..."
						/>
				</Form.Group>

				<Form.Group controlId="formArticleSource">
					<Form.Label className="text-white">Image source:</Form.Label>
					<Form.Control
						type="text"
						name="imgSource"
						value={formik.values.imgSource}
						onChange={formik.handleChange}
						placeholder="Image Source..."
						/>
				</Form.Group>
				{(formik.touched.imgSource && formik.errors.imgSource) && (
					<Alert  variant='danger'>
					    {formik.errors.imgSource}
					 </Alert>
					)}

					<Form.Group controlId="formArticleDate">
						<Form.Label className="text-white">Date:</Form.Label>
						<Form.Control
							type="Date"
							name="articleDate"
							value={formik.values.articleDate}
							onChange={formik.handleChange}

							placeholder="Date..."/>
					</Form.Group>

					{(formik.touched.articleDate && formik.errors.articleDate) && (
						<Alert  variant='danger'>
								{formik.errors.articleDate}
						 </Alert>
						)}

				<Form.Group controlId="formArticleAuthor">
					<Form.Label className="text-white">Author:</Form.Label>
					<Form.Control
						type="text"
						name="author"
						value={formik.values.author}
						onChange={formik.handleChange}
						placeholder="Author..."/>
				</Form.Group>

				{(formik.touched.author && formik.errors.author) && (
					<Alert  variant='danger'>
					    {formik.errors.author}
					 </Alert>
					)}


				<Form.Group>
					<Form.File
						id="formArticleImage"
						label="Principal Image:"
						className="text-white"
						name="principalImage"
						onChange={handlePrincipalImage}
						accept="image/*"
					/>
				</Form.Group>

				<Form.Group>
					<Form.File
						id="formArticleOptionalImages"
						label="Optional Images:"
						className="text-white"
						onChange={handleOptionalImages}
						accept="image/*"
						multiple
						/>
				</Form.Group>

				{alertImages &&
					<Alert  variant='danger'>
					    {alertImages}
					 </Alert>
				}

				<Form.Group controlId="formArticleText">
					<Form.Label className="text-white">Article text:</Form.Label>
					<Form.Control
						as="textarea"
						name="text"
						value={formik.values.text}
						onChange={formik.handleChange}
						rows={5} />
				</Form.Group>

				{(formik.touched.text && formik.errors.text) && (
					<Alert  variant='danger'>
					    {formik.errors.text}
					 </Alert>
					)}

				<Button variant="primary"
					type="submit"
					className="w-100 my-4"
					disabled={isLoading? true : false}
				>
					{article? 'Edit Article' : 'Add Article'}
				</Button>

			</Form>
			</div>
			)
}

export default ArticleForm;
