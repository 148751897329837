import styled from '@emotion/styled';
import {Container} from 'react-bootstrap'

export const Nav = styled.nav`
	background: #000;
	/* height: 80px;
	padding: 10px 0px;
	width: 100%; */
	position: relative;
	font-family: 'Caveat Brush', cursive;
`






export const CloseBar = styled(Container)`

	@media screen and (min-width: 768px) {
	   display: none
	}
`


export const NavContainer = styled(Container)`
	justify-content: space-between;
	display: flex;
	align-items: center;
	padding:10px;


	@media screen and (max-width: 768px) {
		display: flex;
		width: 100%;

	}
`

export const TogglableMenu = styled.button`
	color: white;
	background: none;
	border: none;
	color: #eee;
	&:hover{
		color: white;
	}
	@media screen and (min-width: 768px) {
		display: none;
	}

`

export const Brand = styled.div`
	margin-right: 10px;
	color:white;
	margin-top: 8px;
img {
	width: 140px;

}

div {
	font-size:30px;
	text-decoration:none;
	text-align: center;
}
`


export const Links = styled.div`
	display: flex;
	justify-content:space-evenly;
	padding-top: 10px;
	background: #000;
	width: 100%;
	z-index: 10;
	transition: 0.3s ease;
	font-size: 1.2em;
	&.active{
		left: 0;
	}

	a{
		color: white;
		height: inherit;
		padding: 10px;
		text-align: center;
		text-decoration: none;
		transition: 0.2s linear;
		/* padding-left:130px;
		padding-right:130px; */
		&:hover{
			color: black;
			background: white;
			text-decoration: none;
		}

		@media screen and (max-width: 768px) {
			 height: auto
		}

	}

	@media screen and (min-width: 768px) {
		display: flex;
		position: initial;
		flex-direction: row;
		padding-top: 0;

	}
	@media screen and (max-width: 730px) {
		 height: 100vh;
		 background: #000000bd;

			flex-direction: column;
			position: fixed;
			top: 0;
			left: -100%;
	}

`
